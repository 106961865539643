import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import { Container } from "../components/container";
import { Section } from "../components/Section";
import { Footer } from "../components/Footer";
import { Hero } from "../components/Hero";
import { LinkLight } from "../components/Button";

import Logo from "../assets/MUv2.svg";
import mDev from "../assets/MDEV4.svg";
import uDev from "../assets/UDEV.svg";
import { Toggle } from "../components/Colorchange";
import { MainContent, openChat } from "../components/helpers";

class Index extends React.Component
{
    render()
    {
        const siteTitle = get(this, "props.data.site.siteMetadata.title");
        const siteDescription = get(
            this,
            "props.data.site.siteMetadata.description"
        );

        return (
            <Layout location={ this.props.location }>
                <Helmet
                    htmlAttributes={ { lang: "en" } }
                    meta={ [{ name: "description", content: siteDescription }] }
                    title={ siteTitle }
                />
                <Container>
                    <Hero image={ Logo } imageSize={ "350px" }>
                        <h1>User Experience First</h1>
                        <p>Create the best next thing with MU</p>
                        <br/>
                        <Toggle onClick={openChat} className="base btn-rainbow">Get in touch with us</Toggle>
                    </Hero>
                    <MainContent>
                        <Section image={ mDev }>
                            <h2>M Dev</h2>
                            <p>Modern applications are complex and require the right experience to be managed and
                                maintained
                                in a secure, fast and efficient manner.</p>
                            <LinkLight href={ "/mdev" } className={ "btn btn-light" }>Learn more about M Dev</LinkLight>
                        </Section>
                        <Section image={ uDev }>
                            <h2>U Dev</h2>
                            <p>Your users experience is the most important element of your site and we make this the
                                primary
                                focus as we work with you.</p>
                            <LinkLight href={ "/udev" } className={ "btn btn-light" }>Learn more about U Dev</LinkLight>
                        </Section>
                    </MainContent>
                </Container>
                <Footer/>
            </Layout>
        );
    }
}

export default Index;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
`;