import React from "react";
import heroCss from "./Hero.module.css";

const Hero = (props) => (
    <div className={ heroCss.intro }>
        <div className={ heroCss.image }>
            <img src={ props.image } alt={ "" } width={ props.imageSize }/>
        </div>
        { props.children }
    </div>
);

export {
    Hero
};