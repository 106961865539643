import React from "react";
import sectionCss from "./Section.module.css";
import colorChangeCss from "./Colorchange.module.css";

const Intro = (props) => (
    <div className={ sectionCss.intro }>
        { props.children }
    </div>
);

const Section = (props) => (
    <div className={ `${sectionCss.section} ${(props.flow === "right") ? sectionCss.right : sectionCss.left}` }>
        <div className={ sectionCss.image }>
            <img src={ props.image } alt={ "" } width={ "200px" } height={ "200px" }/>
        </div>
        <div className={ `${sectionCss.text} ${colorChangeCss.background}`}>
            { props.children }
        </div>
    </div>
);

const TextSection = (props) => (
    <div className={ `${sectionCss.textSection} ${(props.flow === "right") ? sectionCss.right : sectionCss.left}` }>
        <div className={ `${sectionCss.text} ${sectionCss.full} ${colorChangeCss.background}` }>
            { props.children }
        </div>
    </div>
);

export {
    Intro,
    Section,
    TextSection
};