import React from 'react'
import * as gatsby from "gatsby";

import buttonCss from "./Button.module.css";
import colorChangeCss from './Colorchange.module.css'

const classes = `${buttonCss.base} ${colorChangeCss.button}`;

const Button = (props) => (
    <a href={ props.href } className={ classes }>{props.children}</a>
);

const Link = (props) => (
    <gatsby.Link to={ props.href } className={ classes }>{props.children}</gatsby.Link>
);

const Toggle = (props) => (
    <button onClick={props.onClick} className={ classes }>{props.children}</button>
);

export {
    Button,
    Link,
    Toggle,
}